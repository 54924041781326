.acceptance-letter {
}

.acceptance-letter-grid-wrapper {
  margin: 0 auto;
}

.acceptance-letter-logos-wrapper {
  padding: 0.5in;
}

.print-button {
  border: none;
  background-color: color(srgb 0.1802 0.4051 0.6957);
  color: white;
  padding: 10px 25px;
  margin-bottom: 25px;
  border-radius: 8px;
  font-family: 'Encode Sans Condensed', sans-serif;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.acceptance-letter-text-wrapper {
  padding: 0 1in;
  font-size: 13pt;
  text-align: justify;
}

.acceptance-letter-text-wrapper p {
  margin: 48px 0;
}

.acceptance-letter-wrapper {
  position: relative;
  border: solid 1px #cbcbcb;
  width: 8.5in;
  height: 11in;
  background-size: 8.5in;
}

.acceptance-letter-signature-wrapper {
  text-align: center;
}

.acceptance-letter-text-wrapper p:last-of-type {
  margin-bottom: 0;
}

.acceptance-letter-title {
  color: #2f5496;
  text-align: center;
}

.signature {
  height: 150px;
}

.unam {
  height: 100px;
  filter: brightness(0);
  bottom: 1in;
  right: 2.5in;
  position: absolute;
}
