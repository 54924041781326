.snn-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.snn-form-group input[type='checkbox'] {
  margin-right: 5px;
}

.snn-abstract-submission-condition {
  margin-bottom: 25px;
  display: block;
  font-weight: 800;
  font-size: 1.1rem;
}

.snn-abstract-submission-condition input[type='checkbox'] {
  margin-right: 15px;
}

.snn-abstract-save-draft-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 50;
  background-color: #225aa9;
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  transition: border-radius 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.snn-abstract-save-draft-button:hover {
  cursor: pointer;
  border-radius: 5px;
}

.snn-abstract-save-draft-button:hover .snn-abstract-save-draft-button-label {
  display: block;
  font-size: 1rem;
}

.snn-abstract-save-draft-button .snn-abstract-save-draft-button-label {
  display: none;
  margin-left: 15px;
  font-size: 0.1rem;
  transition: font-size 0.3s ease-in-out;
}

.snn-form-group label {
  text-align: left;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 15px;
}

.snn-form-group input {
  text-align: left;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  margin: 0;
}

.snn-form-group select {
  text-align: left;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  margin: 0;
}

@media screen and (min-width: 768px) {
}
