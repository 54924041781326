.schedule-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.schedule-links-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.schedule-links-wrapper a {
  display: flex;
  align-items: center;
}

.schedule-grid-day-title {
  font-size: 1rem;
  font-family: 'Fira Code', monospace;
  color: #8b949e;
  font-weight: 400;
  margin-bottom: 0;
}

.schedule-grid-day-date {
  margin-top: 0;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -0.01em;
}

.schedule-grid-event-hour {
  color: #8b949e;
  font-family: 'Fira Code', monospace;
  font-size: 1rem;
  font-weight: 400;
}

.schedule-grid-event-location {
  color: #8b949e;
}

.schedule-grid-map {
  grid-column: 2 / 5;
  text-align: center;
}

.schedule-grid-map img {
  width: 80%;
  height: auto;
}
