.workshop-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.workshop-card-img-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.workshop-card img {
  width: 100%;
}

.workshop-card-description-wrapper {
  margin-top: 15px;
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .workshop-card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    margin-top: 25px;
  }

  .workshop-card-description-wrapper {
    grid-column: 2 / 4;
  }
}
