.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
}

a {
  text-decoration: none !important;
  color: unset;
}

.special-issue-banner-wrapper {
  width: 100%;
}

.special-issue-banner-wrapper img {
  width: 100%;
}
