.snn-speaker-card {
  border: solid 1px #e3e3e3;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition:
    transform 0.2s ease-in-out,
    width 0.2s ease-in-out;
}

.open-card {
  position: fixed;
  top: 10%;
  bottom: 5%;
  left: 50px;
  right: 50px;
  z-index: 10;
  background-color: white;
}

.open-card .snn-speakers-card-img {
  display: none;
}

.open-card .snn-speakers-card-description {
  display: none;
}

@keyframes shield-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.snn-speaker-card:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.snn-speaker-resume {
  text-align: justify;
}

.snn-speaker-card:hover .snn-speaker-shield {
  filter: brightness(1.5);
}

.snn-speaker-shield {
  position: absolute;
  right: -15px;
  bottom: -25px;
  transition: filter 0.2s ease-in-out;
  transform-origin: center;
}

.snn-speaker-shield img {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.23));
}

.snn-speaker-shield img {
  width: 50px;
}

.snn-speakers-card-img {
  flex: 1;
  position: relative;
  align-items: center;
  display: flex;
}

.snn-speakers-card-img img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.snn-speakers-card-description {
  padding: 1rem;
  flex: 1;
  color: white;
  border-radius: 0 0 8px 8px;
}

.special-guest {
  color: black;
}

.snn-speakers-cards-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.snn-keynotes-cards-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.snn-speaker-modal {
  background: rgba(255, 255, 255, 0.92);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  z-index: 50;
}

.snn-speaker-modal-top-bar {
  padding: 10px;
  text-align: right;
}

.hide .snn-speaker-modal-content {
  width: 0;
  height: 0;
  transition:
    width 0.2s ease-in-out,
    height 0.2s ease-in-out;
}

.snn-speaker-modal-affiliation {
  margin-top: 0;
  font-size: 0.9rem;
  color: rgb(92, 92, 92);
}

.snn-speaker-modal-content-main h2 {
  font-size: 2rem;
  font-weight: 100;
  margin: 0;
}

.snn-speaker-modal-content-bottom h3 {
  margin: 0;
  font-size: 2rem;
  font-weight: 100;
}

.snn-speaker-modal-content-bottom div {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.snn-speaker-modal-content {
  padding: 25px;
  overflow-y: auto;
  max-height: calc(100% - 100px);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 25px;
}

.snn-speaker-modal-content-img-wrapper {
  width: 100%;
}

.snn-speaker-modal-content-img-wrapper img {
  width: 100%;
  border-radius: 5px;
}

.snn-speaker-modal-top-bar span:hover {
  cursor: pointer;
}

.snn-speaker-modal-top-bar:hover span {
  transform: scale(1.01);
}

@media (min-width: 600px) {
  .snn-speakers-cards-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .snn-speaker-modal-content {
    grid-template-columns: repeat(4, 1fr);
  }

  .snn-speaker-modal-content-main {
    grid-column: 2 / 4;
  }

  .snn-speaker-modal {
    top: 10%;
    bottom: 10%;
  }

  .snn-keynotes-cards-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
