.snn-panel {
  width: 95%;
  margin: 2rem auto;
  border-radius: 8px;
}

.snn-link-download {
  display: flex;
  color: #2e67b1;
  cursor: pointer;
}

.subtitle-panel-section {
  width: 95%;
  font-family: 'Encode Sans Condensed', sans-serif !important;
  margin: 0 auto;
}

.snn-panel-form-wrapper {
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.snn-panel-title {
  font-weight: 100;
}

.snn-panel-subtitle {
  font-weight: 300;
  margin: 1rem 0;
}

/*
Abstract
 */

.snn-abstract-page {
  width: 95%;
  border: solid 1px #cbcbcb;
  margin: 2rem auto;
}

.snn-abstract-page-title {
  width: 80%;
  margin: 5rem auto 1rem;
  font-family: serif;
  font-size: 1.5rem;
}

.snn-abstract-page-sub-title {
  width: 80%;
  margin: 1rem auto;
  font-family: serif;
  font-size: 1.2rem;
}

.snn-abstract-page-affiliation {
  width: 80%;
  margin: 1rem auto;
  font-family: serif;
  font-size: 10pt;
}

.snn-abstract-page-authors {
  width: 80%;
  margin: 1rem auto;
  font-family: serif;
  font-size: 12pt;
}

.snn-abstract-page-text {
  width: 80%;
  margin: 1rem auto;
  font-family: serif;
  font-size: 12pt;
  text-align: justify;
}

.snn-abstract-page-text img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.snn-abstract-page-text img:active {
  cursor: ew-resize;
}

/*
Editor
 */

.wrapper-class {
  padding: 0;
}

.editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.toolbar-class {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.snn-white-panel {
  background-color: white !important;
}

.snn-abstract-submission-terms-modal {
  position: fixed;
  left: 10%;
  top: 50px;
  right: 10%;
  bottom: 50px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.hide {
  display: none !important ;
}

.snn-abstract-submission-terms-modal-wrapper .hide {
  background-color: transparent;
}

.snn-abstract-submission-terms-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(147, 132, 132, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(147, 132, 132, 0.3);
  transition: background-color 3s ease-in-out;
}

@media screen and (min-width: 768px) {
  .subtitle-panel-section {
    width: 80%;
  }

  .snn-panel-form-wrapper {
    padding: 15px 25px;
    background-color: #f6f6f6;
    border-radius: 8px;
  }

  .snn-panel {
    width: 80%;
    margin: 2rem auto 0;
    padding: 2rem;
    border-radius: 8px;
  }

  .snn-abstract-page {
    width: 8.5in;
    min-height: 11in;
    border: solid 1px #cbcbcb;
    margin: 2rem auto;
    /* Add new page line */
    background: repeating-linear-gradient(white, white 10.58in, #5a5a5a 10.59in, #5a5a5a 10.6in);
  }

  .snn-abstract-page-title {
    width: 80%;
    margin: 5rem auto 1rem;
    font-family: serif;
    font-size: 20pt;
  }

  .snn-abstract-page-sub-title {
    width: 80%;
    margin: 1rem auto;
    font-family: serif;
    font-size: 15pt;
  }

  .snn-abstract-page-affiliation {
    width: 80%;
    margin: 1rem auto;
    font-family: serif;
    font-size: 10pt;
  }

  .snn-abstract-page-authors {
    width: 80%;
    margin: 1rem auto;
    font-family: serif;
    font-size: 12pt;
  }

  .snn-abstract-page-text {
    width: 80%;
    margin: 1rem auto;
    font-family: serif;
    font-size: 12pt;
    text-align: justify;
  }

  .snn-abstract-img-preview {
    height: 100px;
    margin: 0 auto;
    display: block;
  }

  .snn-add-img-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .flex1 {
    flex: 1;
  }

  .snn-panel-author {
    margin-top: 15px;
  }

  .snn-abstract-submission-grid-vertical .snn-panel-form-wrapper {
    padding: unset;
    margin-top: unset;
  }

  .snn-add-img-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    background-color: #75bf42;
    color: white;
    transition: background-color 0.3s ease-in-out;
  }

  .snn-add-img-button:hover {
    cursor: pointer;
    background-color: #9bde6e;
  }

  .snn-abstract-page-image-footer {
    width: 80%;
    margin: 1rem auto;
    font-family: serif;
    font-size: 10pt;
  }

  .snn-abstract-img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  .snn-abstract-submission-affiliation-wrapper {
    display: flex;
    align-items: center;
  }

  .snn-abstract-submission-add-affiliation-button-wrapper {
    text-align: center;
    display: block;
  }

  .overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .snn-abstract-submission-author-actions-wrapper {
    text-align: right;
  }

  .snn-abstract-submission-author-action-button {
    border-radius: 5px;
    margin-right: 10px;
    background-color: transparent;
    border: none;
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
  }

  .snn-abstract-submission-two-columns-form .snn-user-home-main-content-large-semi {
    margin-bottom: 0;
  }

  .snn-abstract-submission-author-action-button:hover {
    cursor: pointer;
    background-color: #ad8525;
    color: white;
  }

  .snn-abstract-submission-author-action-button:last-of-type {
    margin-right: 0;
  }

  .snn-abstract-submission-add-author-button {
    background-color: rgba(25, 35, 82, 1);
    color: white;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    margin: 25px auto;
  }

  .snn-abstract-submission-add-author-button:hover {
    cursor: pointer;
    background-color: rgba(25, 35, 82, 0.8);
  }

  .snn-abstract-submission-add-affiliation-button {
    background-color: transparent;
    color: #75bf42;
    border: none;
    border-radius: 5px;
    transition:
      color 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .snn-abstract-submission-add-affiliation-button:hover {
    cursor: pointer;
    color: #9bde6e;
    transform: scale(1.1);
  }

  .snn-abstract-submission-remove-primary:hover {
    color: #de1717;
    transition: color 0.3s ease-in-out;
  }

  .snn-abstract-submission-two-columns-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 25px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
  }

  .snn-abstract-submission-remove-primary {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    border-radius: 5px;
    border: none;
    color: #bb2020;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .snn-abstract-submission-remove-primary:hover {
    cursor: pointer;
  }

  .snn-abstract-submission-main-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin: 50px 25px 15px;
    width: 100%;
  }

  .snn-abstract-submission-affiliation-form-wrapper {
    flex: 1;
  }

  .snn-expand-grid-4 {
    grid-column: 1 / 5;
  }

  .snn-expand-grid-2 {
    grid-column: 1 / 3;
  }

  .snn-expand-grid-2-2 {
    grid-column: 3 / 5;
  }

  .snn-abstract-submission-grid-vertical {
    display: grid;
    grid-gap: 25px;
    padding-top: 15px;
  }

  .snn-abstract-submission-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }

  .snn-submit-abstract-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  .snn-submit-abstract-buttons-wrapper .button {
    margin-right: 15px;
    padding: 15px;
    border-radius: 5px;
    border: none;
    background-color: #75bf42;
    color: white;
    transition: background-color 0.3s ease-in-out;
  }

  .snn-submit-abstract-buttons-wrapper .button:last-of-type {
    margin-right: 0;
    background-color: rgba(25, 35, 82, 1);
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
}

.flex-start {
  justify-content: flex-start;
  margin-top: 0;
}
