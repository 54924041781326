.snn-topic-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  align-items: flex-start;
}

.top-message-wrapper {
  display: flex;
  flex-direction: row;
  text-align: justify;
  flex-wrap: wrap;
}

.snn-user-home-speakers-wrapper {
  flex-wrap: wrap;
}

.email-link {
  color: color(srgb 0.0018 0.3309 0.7275);
}

.top-message-wrapper div:first-of-type {
  padding-right: 25px;
}

.top-message-wrapper div:last-of-type {
  padding-left: 25px;
}

.snn-topic-section div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.snn-topic-section div img {
  width: 80%;
}

@media screen and (min-width: 1024px) {
  .snn-social-wrapper {
    text-align: center;
  }

  .top-message-wrapper {
    flex-wrap: nowrap;
  }

  .snn-topic-section {
    grid-template-columns: repeat(5, 1fr);
  }

  .snn-topic-section div {
    transition: transform 200ms ease-in-out;
    cursor: pointer;
  }

  .snn-topic-section div:hover {
    transform: scale(1.02);
  }
}
