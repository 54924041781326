.snn-view-abstract-header {
  color: white;
  height: fit-content;
  align-items: center;
  display: flex;
  grid-gap: 25px;
  padding: 25px;
  border-radius: 5px;
}

.select-score {
  width: 100%;
  font-size: 2rem;
}

.title-with-icon {
  align-items: center;
  display: flex;
}

.score-title {
  margin-top: 25px;
}

.textarea-comment {
  width: 90%;
  padding: 15px;
  border-radius: 9px;
  margin: auto;
  display: block;
}

.snn-revisor-abstract-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.snn-user-revisor-authors {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.snn-review-revisor {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  margin-top: 25px;
}

.snn-revisor-card-wrapper-content {
  flex: 1;
}

.snn-revisor-card-img-wrapper {
  width: 100%;
  text-align: right;
}

.snn-abstract-admin-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.snn-abstract-admin-details p {
  margin: 0;
}

.snn-abstract-view-page-wrapper {
  background: none;
}

.snn-view-abstract-header img {
  height: 100px;
  margin-right: 25px;
}

.snn-view-abstract-header-title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.snn-view-abstract-header-title {
  font-weight: 400;
  font-size: 1.5rem;
}

.snn-view-abstract-header-info-wrapper {
  display: flex;
  justify-content: space-between;
}
