.receipt-card {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  background-color: #f6f6f6;
}

.receipt-card:hover {
  cursor: pointer;
  transform: scale(1.01);
}
