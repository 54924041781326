.snn-page-section {
  padding: 1rem;
  width: 80%;
  margin: 0 auto;
  letter-spacing: -0.003em;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
}

.snn-scientific-committee {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.snn-title {
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
}

.snn-photo-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.snn-photo-section div img {
  width: 95%;
}

.snn-banner div {
  width: 100%;
}

.snn-banner div img {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .sosnn-cial-wrapper {
    text-align: center;
  }

  .snn-page-section {
    width: 80%;
  }

  .snn-banner div {
    width: 100%;
  }

  .snn-banner div img {
    width: 100%;
  }

  .snn-about {
    display: flex;
    flex-direction: row-reverse;
  }

  .snn-about #committee {
    flex: 1;
  }

  .snn-about #history {
    flex: 2;
    margin-right: 2rem;
  }

  .snn-photo-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }

  .snn-photo-section div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .snn-photo-section div img {
    width: 80%;
  }

  .snn-topic-section {
    grid-template-columns: repeat(5, 1fr);
  }

  .snn-sponsors-section {
    align-items: center !important;
    justify-content: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .snn-sponsors-section div img {
    width: 60% !important;
  }

  .snn-topic-section div {
    transition: transform 200ms ease-in-out;
    cursor: pointer;
  }
  .snn-topic-section div:hover {
    transform: scale(1.02);
  }
}
